<template>
  <header class="header">
    <div class="header__container">
      <Hamburger v-if="signedIn" />
      <router-link class="header__logo" to="/">
        <img :src="getLogo()" class="header__logo" alt="logo" />
      </router-link>

      <LanguageSwitcher v-if="!signedIn && showLangSwitcher" />

      <div class="header__inner" v-if="signedIn">
        <BaseButton @click="updateUserWantsToSeeJoinPopups(true)" v-if="false"
          >{{ $t("header.enable_join_popups") }}
        </BaseButton>
        <BaseButton @click="updateUserWantsToSeeJoinPopups(false)" v-if="false"
          >{{ $t("header.disable_join_popups") }}
        </BaseButton>
        <div class="header__notifications">
          <NotificationsDropdown />
        </div>
        <div class="header__user">
          <UserDropdown />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import UPDATE_USER_ATTRIBUTES from "@/graphql/user/mutations/UpdateUserAttributes.graphql";
import Hamburger from "@/components/layout/Hamburger";
import NotificationsDropdown from "@/components/notifications/NotificationsDropdown";
import UserDropdown from "@/components/layout/UserDropdown";
import LanguageSwitcher from "@/components/layout/LanguageSwitcher";
import { mapGetters, mapState } from "vuex";
import errorHandler from "@/service/errorHandler";
import LANGUAGE_PICKER_CONFIG from "@/graphql/user/queries/LanguagePickerConfig.graphql";

export default {
  name: "Header",
  components: {
    UserDropdown,
    NotificationsDropdown,
    Hamburger,
    LanguageSwitcher,
  },
  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapGetters("auth", ["getNJUser", "getTenant"]),
  },
  data() {
    return {
      showLangSwitcher: false,
    };
  },
  methods: {
    getLogo() {
      const urlParams = new URLSearchParams(window.location.search);
      const theme = urlParams.get('theme');

      return this.getTenant === 'oneclub' ||  theme === 'oneclub' ? "/one-club-logo.png" : "/nj-logo-big.svg"
    },
    updateUserWantsToSeeJoinPopups(enable) {
      let variables = {
        ...this.getNJUser,
        wantsToSeeJoinPopups: enable,
      };
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_ATTRIBUTES,
          variables: variables,
        })
        .then((data) => {
          this.$store.commit(
            "auth/setNJUserWantsToSeePopup",
            data.data.updateUser
          );
        })
        .catch((error) => {
          errorHandler(
            error,
            "ERROR_UPDATING_USER",
            this.$t("header.error_updating_user")
          );
        });
    },
  },
  apollo: {
    showLangSwitcher: {
      query: LANGUAGE_PICKER_CONFIG,
      update(data) {
        return (
          !data.displayLanguagePicker || data.displayLanguagePicker === "true"
        );
      },
      error(error) {
        errorHandler(
          error,
          "ERROR_LANG_PICKER_CONFIG",
          this.$t("language_picker.error_lang_picker_config")
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  background-color: var(--nj-white);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  z-index: 4;

  &__container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    position: relative;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__notifications {
    margin-right: 25px;
  }

  &__logo {
    display: block;
    width: 100%;
    max-width: 152px;

    html[data-theme='oneclub'] & {
      max-width: 115px;
      margin-left: 0;
    }

    img {
      background-color: transparent;
      display: block;
      width: 100%;
      max-width: 100%;

      html[data-theme='oneclub'] & {
        max-width: 122px;
      }
    }
  }

  ::v-deep {
    .language-switcher__menu {
      bottom: auto;
      top: calc(100% + 15px);
      left: auto;
      right: 0;
    }
  }
}
</style>
